import { Head, Link } from '@inertiajs/react';
import {
  Button,
  Checkbox,
  Container,
  Grid,
  GridCol,
  Group,
  PasswordInput,
  rem,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { FormProvider } from '@/components/form-context';
import GoogleLogin from '@/components/GoogleLogin.jsx';
import useInertiaForm from '@/components/useInertiaForm.jsx';
import { createSiteLayout } from '@/pages/site/layouts/SiteLayout.jsx';

import classes from './Login.module.css';

export default function Login({ errors }) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const form = useInertiaForm(
    {
      initialValues: {
        email: '',
        password: '',
        remember: false,
      },
      validate: {
        email: value => (value ? null : 'Email is required'),
        password: value => (value ? null : 'Password is required'),
      },
    },
    errors,
    null,
    '',
    false,
  );

  return (
    <>
      <Head>
        <title>Login</title>
      </Head>

      <Grid gutter={0} align="stretch">
        <GridCol span={{ base: 12, sm: 7 }} className={classes.gridCol1}>
          <Container>
            <Stack gap={0} align={isMobile ? 'left' : 'center'} justify="center" my={'xl'}>
              <Text c={'gfdark.9'} fz={rem(64)}>
                Login
              </Text>
              <Text fw={'lighter'} c={'gfdark.4'} fz={rem(18)}>
                Welcome to Goldframer’s
              </Text>
              <Text fw={'lighter'} c={'gfdark.4'} fz={rem(18)}>
                Artful world again!
              </Text>

              <Group align={'center'} justify={'space-between'} gap={0} mt="md">
                <a href={route('google')} tabIndex={5}>
                  <GoogleLogin />
                </a>
              </Group>

              <FormProvider form={form}>
                <form onSubmit={e => e.preventDefault()}>
                  <TextInput
                    label="E-MAIL"
                    placeholder="Your E-Mail"
                    size="md"
                    mt={'xs'}
                    classNames={{ input: 'input-large', label: 'input-large-label' }}
                    style={{ minWidth: '350px' }}
                    tabIndex={1}
                    {...form.getInputProps('email')}
                  />
                  <PasswordInput
                    label="PASSWORD"
                    placeholder="Password"
                    size="md"
                    mt={'md'}
                    classNames={{ input: 'input-large', label: 'input-large-label' }}
                    style={{ minWidth: '350px' }}
                    tabIndex={2}
                    {...form.getInputProps('password')}
                  />

                  <Group align={'center'} justify={'space-between'} gap={0} mt="md">
                    <Checkbox label="Remember me" tabIndex={3} {...form.getInputProps('remember', { type: 'checkbox' })} />
                    <a href="/forgot-password" tabIndex={5} className={classes.link}>
                      <Text fz={rem(13)}>Forgot Password?</Text>
                    </a>
                  </Group>

                  <Group align={'center'} justify={'space-between'} gap={0} mt="md">
                    <Button type="submit" tabIndex={4} size="md" onClick={form.handleSubmit}>
                      LOGIN
                    </Button>
                  </Group>

                  <Text mt={'md'} c={'gfdark.4'} fw={'lighter'} fz={rem(18)}>
                    {"Don't have an account? "}
                    <Link href="/register" tabIndex={6} className={classes.link}>
                      Sign Up
                    </Link>
                  </Text>
                </form>
              </FormProvider>
            </Stack>
          </Container>
        </GridCol>
        <GridCol span={{ base: 12, sm: 5 }} visibleFrom={'sm'} className={classes.gridCol2}></GridCol>
      </Grid>
    </>
  );
}

Login.layout = page => createSiteLayout(page, { showFooter: false });
